'use client';

import cn from 'classnames';
import Script from 'next/script';
import { useEffect, useRef } from 'react';

export interface VideoEmbedProps {
	testId?: string;
	videoId: string;
	videoTitle: string;
	videoSettings?: VideoSettings;
	className?: string;
}

interface VideoSettings {
	suggestRelated?: boolean;
	playInline?: boolean;
	autoplay?: boolean;
	showVideoControls?: boolean;
	loop?: boolean;
	disableKbControls?: boolean;
	disableFullscreen?: boolean;
}

export const onPlayerStateChange = (event: YT.OnStateChangeEvent) => {
	const iframe = event.target.getIframe();
	const videoData = iframe.dataset;
	const videoId = videoData.videoid || 'unknown_video_id';
	const videoTitle = videoData.videotitle || 'unknown_video_title';
	switch (event.data) {
		case window.YT.PlayerState.PLAYING:
			window.heap?.track('Video Play', {
				'Video ID': videoId,
				title: videoTitle,
			});
			break;
		case window.YT.PlayerState.ENDED:
			window.heap?.track('Video Finished', {
				'Video ID': videoId,
				title: videoTitle,
			});
			break;
	}
};

/**
 * VideoEmbed component to embed a YouTube video using the YouTube IFrame Player API.
 *
 * @param {videoId} string - https://youtu.be/[video_id]?si=[ignore_this]
 * @param {videoTitle} string - Title of the video
 * @param {videoSettings} VideoSettings - Optional settings for the video
 *
 * @example
 * <VideoEmbed
 *   videoId="dQw4w9WgXcQ"
 *   videoTitle="Example Video"
 *   videoSettings={{
 *     suggestRelated: true,
 *     playInline: true,
 *     showVideoControls: true,
 *     autoplay: false,
 *     loop: false,
 *     disableKbControls: false,
 *   }}
 * />
 */
const VideoEmbed = (props: VideoEmbedProps) => {
	const {
		testId = 'VideoEmbed',
		videoId,
		videoTitle,
		videoSettings,
		className,
	} = props;

	const playerRef = useRef<YT.Player | null>(null);

	useEffect(() => {
		const onYouTubeIframeAPIReady = () => {
			if (window.YT && playerRef.current === null) {
				playerRef.current = new window.YT.Player(videoId, {
					videoId: videoId, // This specifies the video to load
					events: {
						onStateChange: onPlayerStateChange,
					},
					playerVars: {
						rel: videoSettings?.suggestRelated ? 1 : 0,
						playsinline: videoSettings?.playInline ? 1 : 0,
						controls: videoSettings?.showVideoControls ? 1 : 0,
						autoplay: videoSettings?.autoplay ? 1 : 0,
						loop: videoSettings?.loop ? 1 : 0,
						disablekb: videoSettings?.disableKbControls ? 1 : 0,
						fs: videoSettings?.disableFullscreen ? 0 : 1,
					},
				});
			}
		};

		window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
		onYouTubeIframeAPIReady();

		return () => {
			if (window.onYouTubeIframeAPIReady === onYouTubeIframeAPIReady) {
				window.onYouTubeIframeAPIReady = undefined;
			}
		};
	}, [videoId, videoTitle, videoSettings]);

	return (
		<div test-testid={testId}>
			{typeof window !== 'undefined' && window && !window.YT && (
				<Script src="https://www.youtube.com/iframe_api" />
			)}
			<div
				id={videoId}
				data-videoid={videoId}
				data-videotitle={videoTitle}
				className={cn(
					{
						// Default styling for the video embed
						'mb-10 aspect-video min-w-full rounded-2xl lg:mx-auto lg:min-w-156':
							!className,
					},
					className,
				)}
			></div>
		</div>
	);
};

export default VideoEmbed;
